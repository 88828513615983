export const routeSettings = {
  roster: {
    path: "labor",
    title: "Labor",
    roster: {
      path: "roster",
      title: "Roster"
    },
    addWorker: {
      path: "add",
      title: "Add New Worker"
    },
    editWorker: {
      path: "edit/:id",
      title: "Edit Worker"
    },
    profile: {
      path: "profile",
      title: "Profile"
    },
    groupMessage: {
      path: "group-message",
      title: "Group Message"
    },
    createGroupMessage: {
      path: ":id/create",
      title: "Create Group Message"
    },
    updateGroupMessage: {
      path: ":id/update/:groupId",
      title: "Edit Group Message"
    }
  },
  orders: {
    path: "orders",
    title: "Orders",
    createOrder: {
      path: "create-order",
      title: "Create Order"
    },
    editOrder: {
      path: "edit-order/:id",
      title: "Edit Order"
    },
    dispatchOrder: {
      path: "dispatch-order/:id",
      title: "Dispatch Order"
    },
    dispatchList: {
      path: "dispatch-list",
      title: "Dispatch List"
    }
  },
  availability: {
    path: "availability",
    title: "Availability",   
  },
  admin: {
    path: "admin",
    title: "Admin Panel",
    ports: {
      path: "ports",
      title: "Ports - Admin"
    },
    terminals: {
      path: "terminals",
      title: "Terminals - Admin"
    },
    piers: {
      path: "piers",
      title: "Piers - Admin"
    },
    berths: {
      path: "berths",
      title: "Berths - Admin"
    },
    deptCodes: {
      path: "dept-codes",
      title: "Dept Codes - Admin"
    },
    occCodesSteady: {
      path: "steady-occ-codes",
      title: "Steady Occ Codes - Admin"
    },
    unions: {
      path: "unions",
      title: "Unions - Admin"
    },
    vessels: {
      path: "vessels",
      title: "Vessels - Admin"
    },
    employers: {
      path: "employers",
      title: "Employers - Admin"
    },
    certifications: {
      path: "certifications",
      title: "Certifications - Admin"
    },
    userPermissions: {
      path: "users-permissions",
      title: "Users Permissions - Admin"
    },
    reports: {
      path: "reports",
      title: "Reports - Admin"
    },
    messages: {
      path: "messages",
      title: "Messages - Admin"
    },
    suspensionTypes: {
      path: "suspension-types",
      title: "Suspension Types - Admin"
    },
    cargoTypes: {
      path: "cargo-types",
      title: "Occ Codes And Cargo Types - Admin"
    },
    debitTypes: {
      path: "debit-types",
      title: "Debit Types - Admin"
    },
    systemConfigs: {
      path: "system-configs",
      title: "System Configs - Admin"
    },
    notificationGroups: {
      path: "notification-groups",
      title: "Notification Groups - Admin"
    }
  },
  users: {
    path: "users",
    title: "Users"
  },
  timekeeping: {
    path: "timekeeping",
    title: "Timekeeping",
    list: {
      path: "list",
      title: "List Timesheets - Timekeeping"
    },
    byOrder: {
      path: "by-order",
      title: "List Timesheets by orders - Timekeeping"
    },
    editSheet: {
      path: "edit-sheet/:timesheetId",
      title: "Edit Timesheet - Timekeeping"
    },
    activity: {
      path: "activity",
      title: "Activity - Timekeeping"
    },
    jobInfo: {
      path: "job-information",
      title: "Job Information - Timekeeping"
    },
    workersList: {
      path: "workers-list",
      title: "Workers List - Timekeeping"
    },
    exportTimesheet: {
      path: "export-timesheet",
      title: "Export Timesheet - Timekeeping"
    },
    laborOrderTimesheets: {
      path: "timesheets-detail/:id",
      title: "Timesheets detail by labor order - Timekeeping"
    }
  },
  dispatch: {
    path: "dispatch",
    title: "Dispatch Orders",
    dispatchAssign: {
      path: "dispatch-order/:id",
      title: "Dispatch Order"
    }    
  },
  dispatchByDate: {    
    path: "dispatch-by-date",
    title: "Dispatch Orders By Date"
  },
  podium: {
    path: "podium",
    title: "Podium"
  },
  dispatchDashboard: {
    path: "dispatch-dashboard",
    title: "Dispatch Dashboard",
    list: {
      path: "list",
      title: "Dispatch Dashboard"
    },
    availability: {
      path: "availability",
      title: "Availability"
    },
    workboard: {
      path: "workboard",
      title: "Workboard"
    }
  },
  loading: {
    path: "loading"
  },
  reefermechanic: {
    path: "reefermechanic",
    title: "Reefer Mechanic"
  },
  reports: {
    path: "reports",
    title: "Reports",
    details: {
      path: "detail/:id",
      title: "Report Detail"
    }
  },
  upload: {
    path: "upload",
    title: "Upload"
  },
  recordings: {
    path: "recordings",
    title: "Call Recordings"
  },
  profile: {
    path: 'profile',
    title: 'My Profile'
  }
};
